export default [
  "It’s not the years in your life that count. It’s the life in your years.",
  "We write to taste life twice, in the moment and in retrospect.",
  "Pain and suffering are always inevitable for a large intelligence and deep heart. The really great men must, I think, have great sadness on earth.",
  "Waste no more time arguing what a good man should be. Be One.",
  "It never ceases to amaze me: we all love ourselves more than other people, but care more about their opinion than our own.",
  "In your actions, don’t procrastinate.",
  "In your conversations, don’t confuse.",
  "In your thoughts, don’t wander.",
  "In your soul, don’t be passive or aggressive.",
  "In your life, don’t be all about business.",
  "If it is not right, do not do it, if it is not true, do not say it.",
  "You could leave life right now. Let that determine what you do and say and think.",
  "We are more often frightened than hurt; and we suffer more in imagination than in reality.",
  "If a man knows not which port he sails, no wind is favorable.",
  "No person has the power to have everything they want, but it is in their power not to want what they don’t have, and to cheerfully put to good use what they do have.",
  "This is our big mistake: to think we look forward to death. Most of death is already gone. Whatever time has passed is owned by death.",
  "Life is very short and anxious for those who forget the past, neglect the present, and fear the future.",
  "I judge you unfortunate because you have never lived through misfortune...",
  "...You have passed through life without an opponent—no one can ever know what you are capable of, not even you.",
  "People are frugal in guarding their personal property; but as soon as it comes to squandering time they are most wasteful of the one thing in which it is right to be stingy.",
  "First say to yourself what you would be; and then do what you have to do.",
  "Curb your desire—don’t set your heart on so many things and you will get what you need.",
  "Don’t explain your philosophy. Embody it.",
  "What man actually needs is not a tensionless state but rather the striving and struggling for some goal worthy of him.",
  "When we are no longer able to change a situation, we are challenged to change ourselves.",
  "Every graduate here today has the training and the talent to succeed, but do you have the guts to fail?",
  "...If you don't fail, you are not even trying. To get something you never had, you have to do something you never did.",
  "Once a man is united to God, how could he not live forever? Once a man is separated from God, what can he do but wither and die.",
  "Rules for Happiness: something to do, someone to love, something to hope for.",
  "But a lie is a lie, and in itself intrinsically evil, whether it be told with good or bad intents.",
  "Do the right thing because it is right.",
  "If justice perishes, human life on Earth has lost its meaning.",
  "Always recognize that human individuals are ends, and do not use them as means to your end.",
  "Heaven has given human beings three things to balance the odds of life: hope, sleep, and laughter.",
  "Science is organized knowledge. Wisdom is organized life.",
  "It is never too late to become reasonable and wise.",
  "The only thing permanent is change.",
]
