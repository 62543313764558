export const HEADER_TRANSITION_HEIGHT = 200
export const CONTENT_BOOKMARK_ID = "anchor-content"

export const COVERS = [
  {
    label: "winter",
    url: "/images/cover-winter.jpg",
    position: "left center",
  },
  {
    label: "spring",
    url: "/images/cover-spring.jpg",
    position: "center center",
  },
  {
    label: "summer",
    url: "/images/cover-summer.jpg",
    position: "center center",
  },
  {
    label: "autumn",
    url: "/images/cover-autumn.jpg",
    position: "center center",
  },
]

export const HOME_COVER_HEAD_WORDS = [
  { word: "soulwords", delayBefore: 1000, opacity: 0.5 },
  { word: "heartwords", delayBefore: 900, opacity: 0.5 },
  { word: "lifewords", delayBefore: 800, opacity: 0.5 },
  { word: "thislife", delayBefore: 700, opacity: 0.6 },
  { word: "ourlives", delayBefore: 600, opacity: 0.6 },
  { word: "anadventure", delayBefore: 500, opacity: 0.7 },
  { word: "together", delayBefore: 400, opacity: 0.8 },
  { word: "sidebyside", delayBefore: 300, opacity: 0.8 },
  { word: "asone", delayBefore: 300, opacity: 0.8 },
  { word: "doinglife", delayBefore: 300, opacity: 0.8 },
  { word: "sharing", delayBefore: 200, opacity: 0.9 },
  { word: "experiencing", delayBefore: 200, opacity: 0.9 },
  { word: "learning", delayBefore: 200, opacity: 0.9 },
  { word: "crying", delayBefore: 200, opacity: 0.9 },
  { word: "resting", delayBefore: 200, opacity: 0.9 },
  { word: "laughing", delayBefore: 200, opacity: 0.9 },
  { word: "enjoying", delayBefore: 200, opacity: 0.9 },
  { word: "changing", delayBefore: 200, opacity: 0.9 },
  { word: "living", delayBefore: 200, opacity: 0.9 },
  { word: "forever", delayBefore: 200, opacity: 1 },
]
